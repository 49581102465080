
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import LoadingState from '@pixcap/ui-library/components/LoadingState.vue';
	import Button from '@pixcap/ui-library/components/Button.vue';
	import IconWarning from '@pixcap/ui-library/components/Icons/IconWarning.vue';
	import Modal from '@pixcap/ui-library/components/Modal.vue';
	import FlexList from '@/common/FlexList.vue';

	@Component({
		name: 'DeleteConfirmModal',
		components: {
			Button,
			Modal,
			FlexList,
			LoadingState,
			IconWarning,
		},
	})
	export default class DeleteConfirmModal extends Vue {
		@Prop() readonly entity: string;
		@Prop() readonly title: string;
		@Prop() readonly description: string;
		@Prop() readonly showWarningIcon: boolean;

		isLoading = false;

		handleClickDelete() {
			this.isLoading = true;
			this.$emit('handleClickDelete');
		}

		get displayTitle() {
			if (this.title) return this.title;
			return this.$t('common_elements.delete_modal.title', { entity: this.entity });
		}

		get displayDescription() {
			if (this.description) return this.description;
			return this.$t('common_elements.delete_modal.message', { entity: this.entity });
		}
	}
