
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import IconArrowDown from '@pixcap/ui-library/components/Icons/IconArrowDown.vue';
	import FlexList from '@/common/FlexList.vue';

	/// icons list

	type OptionType = {
		name: string;
		text?: string;
		component?: string;
		disabled?: boolean;
		rightInfo?: { component?: any; props?: any; text: string };
	};

	@Component({
		name: 'Dropdown',
		components: {
			FlexList,
			IconArrowDown,
		},
	})
	export default class Dropdown extends Vue {
		@Prop({
			default() {
				return [];
			},
		})
		readonly options: OptionType[];

		@Prop({
			default() {
				return {};
			},
		})
		readonly defaultOption: OptionType;

		@Prop() readonly menuLabel: string;
		@Prop({ default: 'md' }) readonly size: 'sm' | 'md' | 'lg';
		@Prop() readonly placeholder;
		@Prop() readonly fixedList: boolean;
		@Prop() readonly disabled: boolean;

		$refs: {
			refButtonDropList: HTMLButtonElement;
			refDropList: HTMLUListElement;
		};

		get containerClassName() {
			let className = `dropdown-container dropdown--${this.size}`;
			if (this.disabled) className += ' disabled';
			return className;
		}

		@Watch('showDropdownList')
		onFixedListChange() {
			if (this.fixedList) {
				this.$refs.refDropList.style.width = this.$refs.refButtonDropList.clientWidth + 'px';
			}
		}

		showDropdownList = false;
		toggleDropdownList(show: boolean) {
			if (this.disabled) return;
			this.showDropdownList = show;
			if (show) this.$emit('handleShowList');
		}

		handleClickDropdownItem(event: Event, dropdownItem: OptionType) {
			event.stopPropagation();
			if (dropdownItem.disabled) return;
			this.showDropdownList = false;
			if (dropdownItem.name === this.defaultOption.name) return;
			this.$emit('handleMouseDownLeft', dropdownItem, this.defaultOption);
		}
	}
