
	import { Component, Vue, Prop } from 'vue-property-decorator';

	@Component({
		name: 'FlexList',
	})
	export default class FlexList extends Vue {
		@Prop() readonly classes: string;
		@Prop() readonly asList: boolean;

		get className() {
			return this.classes ? this.classes.concat(' flex') : 'flex';
		}
	}
