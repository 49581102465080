
	import { Component, Vue } from 'vue-property-decorator';
	import Modal from '@pixcap/ui-library/components/Modal.vue';

	import { mutationsWrapper as OrganismMutations } from '@/store/organism/wrapper';
	import { mapState } from 'vuex';
	import { IOrganismState, NAMESPACE as ORGANISM_NAMESPACE } from '@/models/store/organism.interface';

	@Component({
		name: 'TutorialModal',
		components: { Modal },
		computed: {
			...mapState(ORGANISM_NAMESPACE, {
				showTutorialModal: (state: IOrganismState) => state.showTutorialModal,
			}),
		},
	})
	export default class TutorialModal extends Vue {
		showTutorialModal: IOrganismState['showTutorialModal'];

		tutorialLink = null;

		handleCloseModal() {
			OrganismMutations.setShowTutorialModal(this.$store, null);
		}

		mounted() {
			this.tutorialLink = this.showTutorialModal || null;
		}
	}
