
	import { Component, Vue } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import { NAMESPACE as USER_NAMESPACE, UserState } from '@pixcap/ui-core/models/store/user.interface';
	import ContactModal from '@pixcap/ui-core/components/contact-us/ContactModal.vue';
	import Notification from '@pixcap/ui-core/components/common/Notification.vue';
	import NetworkStatusModal from '@pixcap/ui-core/components/common/NetworkStatusModal.vue';
	import AuthenticationModal from '@pixcap/ui-core/components/account/authentication/AuthenticationModal.vue';
	import UpgradeMemberModal from '@pixcap/ui-core/components/payment/UpgradeMemberModal.vue';
	import MyAccountModal from '@pixcap/ui-core/components/account/MyAccountModal.vue';
	import CancelPlanModal from '@pixcap/ui-core/components/payment/CancelPlanModal.vue';
	import SubscriptionPaymentMethodModal from '@pixcap/ui-core/components/payment/SubscriptionPaymentMethodModal.vue';
	import CreditsPaymentMethodModal from '@pixcap/ui-core/components/payment/CreditsPaymentMethodModal.vue';
	import PortalPaymentStatusModal from '@pixcap/ui-core/components/payment/PortalPaymentStatusModal.vue';
	import ReferralModal from '@pixcap/ui-core/components/account/referral/ReferralModal.vue';
	import ReadyPlayerMeModal from '@pixcap/ui-core/components/readyplayerme/ReadyPlayerMeModal.vue';
	import { NAMESPACE as READYPLAYERME_NAMESPACE, IReadyPlayerMe } from '@pixcap/ui-core/models/store/readyplayerme.interface';
	import { IExportState, NAMESPACE as EXPORT_NAMESPACE } from '@pixcap/ui-core/models/store/projectexports.interface';
	import CancelServerExportModal from '@pixcap/ui-core/components/download-queue/CancelServerExportModal.vue';
  import VideoDownloadTopUpModal from '@pixcap/ui-core/components/payment/VideoDownloadTopUpModal.vue';
  import CartPaymentMethodModal from '@pixcap/ui-core/components/payment/CartPaymentMethodModal.vue';
  import CartPaymentStatusPopoverModal from '@pixcap/ui-core/components/payment/CartPaymentStatusPopoverModal.vue';
	import { NAMESPACE as LIBRARY_NAMESPACE, IAssetsLibraryState } from '@/models/store/pixcapassets.interface';
	import { NAMESPACE as PREVIEW_NAMESPACE, IPreviewerState } from '@/models/store/preview.interface';
	import { NAMESPACE as COMMUNITY_NAMESPACE, ICommunityState } from '@/models/store/community.interface';
	import LibraryItemDetailsModal from '@/components/pixcap-library/LibraryItemDetailsModal.vue';

	import CommunityItemDetailsModal from '@/components/community/CommunityItemDetailsModal.vue';
	import DownloadPreviewModal from '@/components/assets/DownloadPreviewModal.vue';
	import TutorialModal from '@/common/TutorialModal.vue';
	import { IOrganismState, NAMESPACE as ORGANISM_NAMESPACE } from '@/models/store/organism.interface';
	import LibraryTemplateDetailsModal from '@/components/pixcap-library/library-templates/LibraryTemplateDetailsModal.vue';


	@Component({
		name: 'AppGlobals',
		components: {
			CartPaymentStatusPopoverModal,
			CartPaymentMethodModal,
			CancelServerExportModal,
			VideoDownloadTopUpModal,
			LibraryTemplateDetailsModal,
			CommunityItemDetailsModal,
			LibraryItemDetailsModal,
			PortalPaymentStatusModal,
			AuthenticationModal,
			NetworkStatusModal,
			UpgradeMemberModal,
			CancelPlanModal,
			MyAccountModal,
			ContactModal,
			SubscriptionPaymentMethodModal,
			CreditsPaymentMethodModal,
			ReadyPlayerMeModal,
			Notification,
			DownloadPreviewModal,
			TutorialModal,
			ReferralModal,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				showNoAuthModal: (state: UserState) => state.showNoAuthModal,
				showResetPasswordModal: (state: UserState) => state.showResetPasswordModal,
				showCancelPlanModal: (state: UserState) => state.showCancelPlanModal,
				upgradeModalState: (state: UserState) => state.upgradeModalState,
				topUpLimitsModalState: (state: UserState) => state.topUpLimitsModalState,
				showContactModal: (state: UserState) => state.showContactModal,
				showReferralModal: (state: UserState) => state.showReferralModal,
				showPortalPaymentStatus: (state: UserState) => state.showPortalPaymentStatus,
				selectedSubscriptionForPayment: (state: UserState) => state.selectedSubscriptionForPayment,
				toppedCreditsQuantityForPayment: (state: UserState) => state.toppedCreditsQuantityForPayment,
				showCartPaymentMethodModal: (state: UserState) => state.showCartPaymentMethodModal,
				showCartPaymentStatusPopoverModal: (state: UserState) => state.showCartPaymentStatusPopoverModal,
			}),
			...mapState(COMMUNITY_NAMESPACE, {
				selectedCommunityItemSlug: (state: ICommunityState) => state.selectedCommunityItemSlug,
			}),
			...mapState(LIBRARY_NAMESPACE, {
				selectedLibraryItemSlug: (state: IAssetsLibraryState) => state.selectedLibraryItemSlug,
				selectedLibraryTemplateSlug: (state: IAssetsLibraryState) => state.selectedLibraryTemplateSlug,
			}),
			...mapState(PREVIEW_NAMESPACE, {
				previewImage: (state: IPreviewerState) => state.previewImage,
			}),
			...mapState(READYPLAYERME_NAMESPACE, {
				showReadyPlayerMeModal: (state: IReadyPlayerMe) => state.showReadyPlayerMeModal,
			}),
			...mapState(ORGANISM_NAMESPACE, {
				showTutorialModal: (state: IOrganismState) => state.showTutorialModal,
			}),
			...mapState(EXPORT_NAMESPACE, {
				serverExportItemToCancel: (state: IExportState) => state.serverExportItemToCancel,
			}),
		},
	})
	export default class AppGlobals extends Vue {
		showNoAuthModal: UserState['showNoAuthModal'];
		showResetPasswordModal: UserState['showResetPasswordModal'];
		showCancelPlanModal: UserState['showCancelPlanModal'];
		showContactModal: UserState['showContactModal'];
		showReferralModal: UserState['showReferralModal'];
		upgradeModalState: UserState['upgradeModalState'];
		topUpLimitsModalState: UserState['topUpLimitsModalState'];
		selectedSubscriptionForPayment: UserState['selectedSubscriptionForPayment'];
		toppedCreditsQuantityForPayment: UserState['toppedCreditsQuantityForPayment'];
		showCartPaymentMethodModal: UserState['showCartPaymentMethodModal'];
		showCartPaymentStatusPopoverModal: UserState['showCartPaymentStatusPopoverModal'];
		selectedLibraryItemSlug: IAssetsLibraryState['selectedLibraryItemSlug'];
		selectedLibraryTemplateSlug: IAssetsLibraryState['selectedLibraryTemplateSlug'];
		showPortalPaymentStatus: UserState['showPortalPaymentStatus'];
		selectedCommunityItemSlug: ICommunityState['selectedCommunityItemSlug'];
		previewImage: IPreviewerState['previewImage'];
		showReadyPlayerMeModal: IReadyPlayerMe['showReadyPlayerMeModal'];
		showTutorialModal: IOrganismState['showTutorialModal'];
		serverExportItemToCancel: IExportState['serverExportItemToCancel'];

		avatarCreated(params) {
			this.$router.push({ name: 'DesignCreateMiddleware', query: { ...this.$route.query, ...params } });
		}
	}
